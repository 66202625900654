// utils
import { statusList } from 'translations/common';

export default {
  appComponent: {
    footer: {
      modal: {
        title: 'Are you sure you want to start fresh?',
        declineText: 'Close',
        submitText: 'Restart',
        text: 'This will start your progress over and erase all previous information.',
      },
      text: 'Over?',
      button: 'START OVER',
      privacyLink: 'Privacy Policy',
      contactLink: 'Contact Us',
    },
  },
  filePage: {
    description: 'Please enter the email you used to make your purchase.',
    contactInfo: {
      linkText: 'Forgot which email was used?',
      title: "You don't have the email used to make your purchase?",
      introVerbiage:
        'That’s okay!  With a little assistance from you, we should be able to find your information. If you need help, please',
    },
    fileClaim: {
      buttonName: 'Send Login Link',
      placeholder: 'Email',
    },
  },
  emailSent: {
    confirmation:
      'If your email is valid and any active orders are found, a login link has been emailed to ',
    header: 'Check Your Inbox. You Have a Login Link Waiting for You!',
    routerLink: 'Resend Login Link Email',
    wrong: 'Used the wrong email address? ',
    spam: ' and be sure to check your spam folder just in case.',
    button: 'Click here',
    request: "Didn't get the email? ",
  },
  orderSelectionPage: {
    dateFilter: {
      title: 'Date Range',
      labels: {
        from: 'Start Date',
        to: 'End Date',
      },
      buttons: {
        filter: 'Search',
        cancel: 'Cancel',
      },
    },
    rules: {
      title: 'Some Guidelines:',
    },
    links: {
      newFlow: 'View Terms',
      help: 'Help Center',
    },
    header: {
      title: 'Your Orders',
      row1: 'Your previous orders are below. ',
    },
    pagination: {
      title: 'Order Page: ',
    },
    orderTableWrapper: {
      error: 'Sorry, you can only select items within the same order.',
    },
    itemTable: {
      statusColumn: {
        header: 'Status',
        status: statusList,
      },
    },
  },
  reviewPage: {
    header: {
      title: 'Final Review',
    },
    sign: {
      title: '*Please sign below',
      claims: {
        link: {
          title: 'here',
          text: {
            row1: '. Furthermore this consent allows us to contact you via email for ',
          },
        },
      },
    },
  },
  thankYouPage: {
    button: 'Finished',
    feedback: {
      button: 'Send Feedback',
      text: 'Feedback Sent!',
    },
    formControlLabel: 'I would like to receive news and updates about Protecht',
    textAreaDescription: 'Write your description',
    commentText: 'Please leave any comments regarding your experience',
    rateText: 'Please rate your experience',
    header: {
      description: {
        row1: 'Your ',
        row3: ' number is:',
      },
    },
    link: 'contact us',
    footer: '.',
  },
  addressForm: {
    address: {
      placeholder1: 'Address line 1',
      placeholder2: 'Address line 2',
    },
    phone: 'Phone Number',
    menuItem: 'Country',
  },
  contactInfo: {
    text: 'Email:',
    button: 'Close',
    description: 'contact us',
  },
  perilsPage: {
    title: '*Please select one covered reason',
  },
};
